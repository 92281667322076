import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';

/**
 * Add new language to the list of preferred languages. If the list of available languages
 * is provided, consider them less preferred than the selected one.
 * @param {String[]} oldLanguagePreference
 * @param {String} newPreferredLanguage
 * @param {String[]} availableLanguages
 */
function getNewLanguagePreference(
  oldLanguagePreference,
  newPreferredLanguage,
  availableLanguages,
) {
  if (!oldLanguagePreference) {
    return [newPreferredLanguage];
  }
  const order = {};
  forEach(oldLanguagePreference, (l, i) => {
    // add "1" because we don't want zero
    order[l] = i + 1;
  });
  if (!order[newPreferredLanguage]) {
    order[newPreferredLanguage] = Infinity;
  }
  forEach(availableLanguages, (l) => {
    if (newPreferredLanguage !== l && order[l]) {
      if (
        !order[newPreferredLanguage] ||
        order[newPreferredLanguage] >= order[l]
      ) {
        order[newPreferredLanguage] = order[l] - 0.5;
      }
    }
  });
  return sortBy(keys(order), (l) => order[l]);
}

export default getNewLanguagePreference;
