import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { languages } from 'countries-list';
import { supportedLanguages as supportedUiLanguages } from './i18next';
import { selectLanguagePreference } from '../store/preferences';
import useQuestionnaire from './useQuestionnaire';

const getLanguageLabel = (value) => {
  return languages[value] ? languages[value].name : value;
};

const getLanguageNative = (value) => {
  return languages[value] ? languages[value].native : value;
};

const useLanguageOptions = (
  currentAnswersSheetId,
  defaultProjectLanguages = [],
) => {
  const { translations, projectLanguages, loadingQuestionnaire } =
    useQuestionnaire(currentAnswersSheetId);

  const allProjectLanguages = currentAnswersSheetId
    ? projectLanguages
    : defaultProjectLanguages;

  const languagePreference = useSelector(selectLanguagePreference);
  const languageOptions = useMemo(() => {
    const allFeaturesSupported = (features) => {
      if (currentAnswersSheetId) {
        return !!(features.ui && features.translation && features.project);
      }
      return !!(features.ui && features.project);
    };
    const order = {};
    const options = [];
    const features = {};
    forEach(translations, ({ language }) => {
      features[language] = {
        ...features[language],
        translation: true,
      };
    });
    forEach(supportedUiLanguages, (language) => {
      features[language] = {
        ...features[language],
        ui: true,
      };
    });
    forEach(allProjectLanguages, (language) => {
      features[language] = {
        ...features[language],
        project: true,
      };
    });
    forEach(languagePreference, (language, index) => {
      if (features[language]) {
        // We want at least one feature to be supported. Otherwise,
        // there's no point in exposing the language option.
        options.push({
          value: language,
          label: getLanguageLabel(language),
          native: getLanguageNative(language),
          warning: !allFeaturesSupported(features[language]),
        });
      }
      order[language] = index;
    });
    forEach(allProjectLanguages, (language) => {
      if (features[language]) {
        options.push({
          value: language,
          label: getLanguageLabel(language),
          native: getLanguageNative(language),
          warning: !allFeaturesSupported(features[language]),
        });
      } else {
        options.push({
          value: language,
          label: getLanguageLabel(language),
          native: getLanguageNative(language),
          warning: true,
        });
      }
    });
    return sortBy(uniqBy(options, 'value'), ['label']);
  }, [
    currentAnswersSheetId,
    allProjectLanguages,
    languagePreference,
    translations,
  ]);
  return {
    languageOptions,
    languageOptionsLoading: !!loadingQuestionnaire,
  };
};

export default useLanguageOptions;
