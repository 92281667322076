import { createSelector } from 'reselect';
import { registerStore } from '../common/utilsClient/multiReducer';
import {
  getNavigatorLanguage,
  getPreferredLanguage,
  getCurrentLanguage,
  changeLanguage,
} from '../common/utils/i18next';
import { supportedLanguages } from '../utils/i18next';
import getNewLanguagePreference from '../common/utils/getNewLanguagePreference';

const initialState = {
  languagePreference: [getNavigatorLanguage()],
  fontSize: 16,
  animationSpeed: 400,
  theme: null,
  // FIXME: Move to another location
  dexcomAccessToken: null,
  dexcomAccessTokenExpiresAt: null,
  dexcomRefreshToken: null,
  originalFormURL: null,
};

const store = registerStore({
  path: 'preferences',
  initialState,
});

export const getAnimationSpeed = store.get('animationSpeed');
export const selectLanguagePreference = store.get('languagePreference');
export const createSelectPreferredLanguage = (availableLanguages) =>
  createSelector(selectLanguagePreference, (languagePreference) =>
    getPreferredLanguage(languagePreference, availableLanguages),
  );

export const setLanguagePreference = store.create.set('languagePreference');
export const selectPreferredUiLanguage =
  createSelectPreferredLanguage(supportedLanguages);

export const setPreferredLanguage =
  (newPreferredLanguage, availableLanguages) => (dispatch, getState) => {
    const state = getState();
    const oldLanguagePreference = selectLanguagePreference(state);
    const newLanguagePreference = getNewLanguagePreference(
      oldLanguagePreference,
      newPreferredLanguage,
      availableLanguages,
    );
    dispatch(setLanguagePreference(newLanguagePreference));
  };

export const createMiddleware = () => {
  return ({ getState }) => {
    //---------------------------------------
    return (next) => (action) => {
      const result = next(action);
      const preferredLanguage = selectPreferredUiLanguage(getState());
      const currentLanguage = getCurrentLanguage();
      if (preferredLanguage !== currentLanguage) {
        changeLanguage(preferredLanguage);
      }
      return result;
    };
  };
};

export default store;
