import has from 'lodash/has';
import map from 'lodash/map';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';
import isRegExp from 'lodash/isRegExp';
import size from 'lodash/size';
import keys from 'lodash/keys';
import isDate from 'lodash/isDate';
import isNaN from 'lodash/isNaN';
import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import every from 'lodash/every';
import some from 'lodash/some';

// reference: https://www.hl7.org/fhir/datatypes.html
const formats = {
  decimal: {
    re: /^-?(0|[1-9][0-9]*)(\.[0-9]+)?([eE][+-]?[0-9]+)?$/,
    description: 'decimal number',
    example: '0.0',
  },
  instant: {
    // eslint-disable-next-line max-len
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))$/,
    description: 'timestamp',
    example: 'YYYY-MM-DDThh:mm:ssZ',
  },
  date: {
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1]))?)?$/,
    description: 'date or partial date',
    example: 'YYYY-MM-DD',
  },
  'date-time': {
    // eslint-disable-next-line max-len
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])(T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00)))?)?)?$/,
    description: 'date and time of day',
    example: 'YYYY-MM-DDThh:mm:ssZ',
  },
  time: {
    re: /^([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?$/,
    description: 'time of day',
    example: 'hh:mm:ss',
  },
  year: {
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)$/,
    description: 'year',
    example: 'YYYY',
  },
  'full-date': {
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])))$/,
    description: 'full date',
    example: 'YYYY-MM-DD',
  },
  email: {
    re: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    description: 'valid email',
    example: 'john@example.com',
  },
  phone: {
    re: /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
    description: 'up to 14 digits prefixed with country code and "+"',
    example: '',
  },
};

const prefix = (n) => {
  return (value) => {
    if (typeof value === 'string') {
      return value.substr(0, n);
    }
    return undefined;
  };
};

const formatsHierarchy = {
  instant: {
    is: ['date-time'],
    projections: {
      year: prefix(4),
      date: prefix(10),
      'full-date': prefix(10),
    },
  },
  date: {
    is: ['date-time'],
    projections: {
      year: prefix(4),
    },
  },
  'date-time': {
    is: [],
    projections: {
      year: prefix(4),
      date: prefix(10),
    },
  },
  year: {
    is: ['date', 'date-time'],
  },
  'full-date': {
    is: ['date', 'date-time'],
    projections: {
      year: prefix(4),
    },
  },
};

const identity = (x) => x;

export function getProjectionsTo(targetFormat) {
  const projections = [];
  forEach(formatsHierarchy, (settings, format) => {
    if (includes(settings.is, targetFormat)) {
      projections.push({
        format,
        isSubType: true,
        projection: identity,
      });
    } else if (settings.projections && settings.projections[targetFormat]) {
      projections.push({
        format,
        isSubType: false,
        projection: settings.projections[targetFormat],
      });
    }
  });
  return projections;
}

function strLength(str) {
  if (!str) {
    return 0;
  }
  // https://stackoverflow.com/a/54369605/2817257
  return [...str].length;
}

const defaultUriResolver = (_, relativeURI) => relativeURI;

export const isMoreGenericFormatThan = (format1, format2) => {
  const hierarchy = formatsHierarchy[format2];
  if (!hierarchy) {
    return false;
  }
  return includes(hierarchy.is, format1);
};

export function getPattern(format) {
  if (formats[format]) {
    return formats[format].re.source;
  }
  return null;
}

export function checkFormat(formatName, value) {
  const format = formats[formatName];
  if (!format) {
    return {
      message: `Unknown format: ${formatName}`,
    };
  }
  if (!format.re.test(value)) {
    return {
      message: `Expected ${format.description}`,
    };
  }
  return undefined;
}

export function getPatternErrorMessage(format) {
  if (formats[format]) {
    return `Expected ${formats[format].description}`;
  }
  return null;
}

export function getPatternExample(format) {
  if (formats[format]) {
    return formats[format].example;
  }
  return null;
}

export const isAtomicType = (type) => {
  switch (type) {
    case 'number':
    case 'integer':
    case 'string':
    case 'boolean':
    case 'null':
      return true;
    // NOTE: The following are valid BSON types.
    case 'double':
    case 'decimal':
    case 'int':
    case 'long':
    case 'regex':
    case 'date': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const isAtomicValue = (value) => {
  return (
    typeof value === 'number' ||
    typeof value === 'string' ||
    typeof value === 'boolean' ||
    isNil(value) ||
    isDate(value) ||
    isRegExp(value)
  );
};

// NOTE: There's a small caveat here. The function is not clever enough
//       to detect that some combinations of allOf, may lead to NOTHING
//       type. For example allOf: array, object and allOf: number, object
//       are both representing NOTHING, but in the first case isAtomic
//       will return "false" and in the second case it will return "true".
//       So we agree that for NOTHING type, the result if isAtomic is not
//       well determined and it depends on the context. However, if schema
//       is not provided at all, we return false.
export const isAtomic = (schema) => {
  if (!schema) {
    return false;
  }
  if (schema.type && isAtomicType(schema.type)) {
    return true;
  }
  if (schema.bsonType && isAtomicType(schema.bsonType)) {
    return true;
  }
  if (schema.anyOf && every(schema.anyOf, isAtomic)) {
    return true;
  }
  if (schema.oneOf && every(schema.oneOf, isAtomic)) {
    return true;
  }
  if (schema.allOf && some(schema.allOf, isAtomic)) {
    return true;
  }
  if (schema.enum && every(schema.enum, isAtomicValue)) {
    return true;
  }
  if (has(schema, 'const') && isAtomicValue(schema.const)) {
    return true;
  }
  if (has(schema, 'if') && isAtomic(schema.then) && isAtomic(schema.else)) {
    return true;
  }
  return false;
};

export function createCheckSchema({
  rootSchema,
  baseURI = '',
  remoteSchemas,
  uriResolver = defaultUriResolver,
} = {}) {
  const localSchemas = {};
  const allSchemas = Object.create(remoteSchemas || {});

  const defineLocalSchema = (uri, schema) => {
    localSchemas[uri] = schema;
    allSchemas[uri] = schema;
  };

  let rootURI = baseURI;
  if (rootSchema) {
    rootURI = uriResolver(baseURI, rootSchema.$id || '');
    defineLocalSchema(rootURI, rootSchema);
  }

  forEach(rootSchema && rootSchema.definitions, (schema) => {
    if (schema.$id) {
      defineLocalSchema(uriResolver(rootURI, schema.$id), schema);
    }
  });

  const getValidateCache = {};
  const getValidate = (relativeURI) => {
    const uri = uriResolver(rootURI, relativeURI);
    if (getValidateCache[uri]) {
      return getValidateCache[uri].checkSchema;
    }
    let validator;
    if (localSchemas[uri]) {
      validator = createCheckSchema({
        uriResolver,
        rootSchema: localSchemas[uri],
        baseURI: uri,
        remoteSchemas: allSchemas,
      });
    } else if (remoteSchemas && remoteSchemas[uri]) {
      validator = createCheckSchema({
        uriResolver,
        remoteSchemas,
        baseURI: uri,
        rootSchema: remoteSchemas[uri],
      });
    }
    getValidateCache[uri] = {
      checkSchema: validator,
    };
    return validator;
  };

  const checkSchema = (valueSchema, value) => {
    if (valueSchema === true) {
      return undefined;
    }
    if (valueSchema === false) {
      return {
        message: 'No value is accepted',
      };
    }
    if (!isPlainObject(valueSchema)) {
      return {
        message: 'Unrecognized schema specification',
      };
    }
    if (typeof valueSchema.$ref === 'string') {
      const [relativeURI = '', jPointer = ''] = valueSchema.$ref.split('#');

      if (!relativeURI || relativeURI === rootURI) {
        if (jPointer === '') {
          return checkSchema(rootSchema, value);
        }
        if (jPointer.charAt(0) === '/') {
          const key = jPointer
            .substr(1)
            .split('/')
            .map((chunk) =>
              decodeURIComponent(chunk).replace(/~1/g, '/').replace(/~0/g, '~'),
            )
            .join('.');

          const subSchema = get(rootSchema, key);
          if (subSchema) {
            return checkSchema(subSchema, value);
          }
        }
      }

      if (jPointer && jPointer.charAt(0) !== '/') {
        const validate = getValidate(valueSchema.$ref);
        // NOTE: Double check if validate is a new function, to prevent infinite call stack.
        if (validate && validate !== checkSchema) {
          return validate(
            {
              $ref: '',
            },
            value,
          );
        }
      }

      if (relativeURI && relativeURI !== rootURI) {
        const validate = getValidate(relativeURI);
        // NOTE: Double check if validate is a new function, to prevent infinite call stack.
        if (validate && validate !== checkSchema) {
          return validate(
            {
              $ref: `#${jPointer}`,
            },
            value,
          );
        }
      }

      return {
        message: 'Bad reference',
      };
    }
    if (typeof value === 'undefined') {
      return undefined;
    }
    if (isArray(valueSchema.type)) {
      const errors = checkSchema(
        {
          anyOf: map(valueSchema.type, (type) => ({
            type,
          })),
        },
        value,
      );
      if (errors) {
        return {
          message: `Value should be any of: ${valueSchema.type.join(', ')}`,
        };
      }
    }
    if (typeof valueSchema.type === 'string') {
      switch (valueSchema.type) {
        case 'null': {
          if (value !== null) {
            return {
              message: 'Value is not null',
            };
          }
          break;
        }
        case 'string': {
          if (typeof value !== 'string') {
            return {
              message: 'Value is not a string',
            };
          }
          if (valueSchema.format) {
            const error = checkFormat(valueSchema.format, value);
            if (error) {
              return error;
            }
          }
          break;
        }
        case 'integer':
        case 'number': {
          if (typeof value !== 'number' || isNaN(value)) {
            return {
              message: 'Value is not a number',
            };
          }
          if (valueSchema.type === 'integer' && value % 1 !== 0) {
            // NOTE: at this stage we already know that it's a number
            return {
              message: 'Value is not an integer',
            };
          }
          break;
        }
        case 'boolean': {
          if (typeof value !== 'boolean') {
            return {
              message: 'Value is not a boolean',
            };
          }
          break;
        }
        case 'object': {
          if (!isPlainObject(value)) {
            return {
              message: 'Value is not an object',
            };
          }
          break;
        }
        case 'array': {
          if (!isArray(value)) {
            return {
              message: 'Value is not an array',
            };
          }
          break;
        }
        case 'date': {
          if (!isDate(value) || isNaN(value.getTime())) {
            return {
              message: 'Value is not a date',
            };
          }
          break;
        }
        default: {
          // NOTE: We want support {} representing "any" type
          if (valueSchema.type) {
            return {
              message: `I don't know how to validate type: ${valueSchema.type}`,
            };
          }
        }
      }
    }
    if (typeof valueSchema.bsonType === 'string') {
      // See: https://docs.mongodb.com/manual/reference/bson-types/
      const { bsonType } = valueSchema;
      switch (valueSchema.bsonType) {
        // NOTE: In fact "number" is an alias for integer, decimal, double, or long and I am
        //       not sure how it should be represented in JavaScript (probably via MongoDB custom types).
        //       But for our purposes, it's enough to handle JS built-in Number.
        case 'string':
        case 'object':
        case 'array':
        case 'null':
        case 'number':
        case 'date': {
          const error = checkSchema(
            {
              type: bsonType,
            },
            value,
          );
          if (error) {
            return error;
          }
          break;
        }
        case 'bool': {
          const error = checkSchema(
            {
              type: 'boolean',
            },
            value,
          );
          if (error) {
            return error;
          }
          break;
        }
        case 'regex': {
          if (!isRegExp(value)) {
            return {
              message: 'Value is not an object',
            };
          }
          break;
        }
        default: {
          return {
            message: `I don't know how to validate type: ${valueSchema.type}`,
          };
        }
      }
    }
    if (isArray(valueSchema.enum)) {
      if (findIndex(valueSchema.enum, (x) => isEqual(x, value)) < 0) {
        if (size(valueSchema.enum) === 0) {
          return {
            message: 'No value is accepted',
          };
        }
        if (size(valueSchema.enum) === 1) {
          return {
            message: `Value is not equal to ${valueSchema.enum[0]}`,
          };
        }
        return {
          message: `Value should be one of: ${valueSchema.enum.join(', ')}`,
        };
      }
    }
    if (has(valueSchema, 'const')) {
      if (!isEqual(valueSchema.const, value)) {
        return {
          message: `Value is not equal to ${valueSchema.const}`,
        };
      }
    }
    if (typeof value === 'number') {
      if (
        typeof valueSchema.minimum === 'number' &&
        value < valueSchema.minimum
      ) {
        return {
          message: `Expected value at least ${valueSchema.minimum}`,
        };
      }
      if (
        typeof valueSchema.exclusiveMinimum === 'number' &&
        value <= valueSchema.exclusiveMinimum
      ) {
        return {
          message: `Expected value greater than ${valueSchema.exclusiveMinimum}`,
        };
      }
      if (
        typeof valueSchema.maximum === 'number' &&
        value > valueSchema.maximum
      ) {
        return {
          message: `Expected value at most ${valueSchema.maximum}`,
        };
      }
      if (
        typeof valueSchema.exclusiveMaximum === 'number' &&
        value >= valueSchema.exclusiveMaximum
      ) {
        return {
          message: `Expected value less than ${valueSchema.exclusiveMaximum}`,
        };
      }
      if (
        typeof valueSchema.multipleOf === 'number' &&
        (value / valueSchema.multipleOf) % 1 !== 0
      ) {
        return {
          message: `Expected value to be multiple of ${valueSchema.multipleOf}`,
        };
      }
    }
    if (typeof value === 'string') {
      if (
        typeof valueSchema.minLength === 'number' &&
        strLength(value) < valueSchema.minLength
      ) {
        return {
          message: `Expected length at least ${valueSchema.minLength}`,
        };
      }
      if (
        typeof valueSchema.maxLength === 'number' &&
        strLength(value) > valueSchema.maxLength
      ) {
        return {
          message: `Expected length at most ${valueSchema.maxLength}`,
        };
      }
      if (typeof valueSchema.pattern === 'string') {
        let re;
        try {
          re = new RegExp(valueSchema.pattern);
        } catch (err) {
          return {
            type: 'pattern',
            message: 'Invalid regular expression',
          };
        }
        if (!re.test(value)) {
          return {
            type: 'pattern',
            message:
              valueSchema.examples && valueSchema.examples[0]
                ? `Value should be of the form: ${valueSchema.examples[0]}`
                : `Value should match pattern: ${valueSchema.pattern}`,
          };
        }
      }
    }
    if (isPlainObject(value)) {
      const required = {};
      const errors = {};
      forEach(valueSchema.required, (key) => {
        required[key] = true;
      });
      const patterns = [];
      forEach(valueSchema.patternProperties, (schema, key) => {
        patterns.push({
          schema,
          regExp: new RegExp(key),
        });
      });
      const matched = {};
      forEach(value, (valueAtKey, key) => {
        if (valueSchema.properties && has(valueSchema.properties, key)) {
          const error = checkSchema(valueSchema.properties[key], valueAtKey);
          if (error) {
            errors[key] = error;
            return;
          }
          matched[key] = true;
        }
        for (let i = 0; i < patterns.length; i += 1) {
          const { schema, regExp } = patterns[i];
          if (regExp.test(key)) {
            const error = checkSchema(schema, valueAtKey);
            if (error) {
              errors[key] = error;
              return;
            }
            matched[key] = true;
          }
        }
        if (!matched[key]) {
          if (has(valueSchema, 'additionalProperties')) {
            const error = checkSchema(
              valueSchema.additionalProperties,
              valueAtKey,
            );
            if (error) {
              errors[key] = error;
            }
          }
        }
      });
      if (!isEmpty(errors)) {
        return {
          errors,
        };
      }
      if (isArray(valueSchema.required)) {
        const n = valueSchema.required.length;
        for (let i = 0; i < n; i += 1) {
          const name = valueSchema.required[i];
          if (value[name] === undefined) {
            return {
              message: `Missing required property "${name}"`,
            };
          }
        }
      }
      if (has(valueSchema, 'propertyNames')) {
        const properties = keys(value);
        const n = properties.length;
        for (let i = 0; i < n; i += 1) {
          const error = checkSchema(valueSchema.propertyNames, properties[i]);
          if (error) {
            return {
              message: `${properties[i]}: ${error.message}`,
            };
          }
        }
      }
      if (isPlainObject(valueSchema.dependencies)) {
        const properties = keys(valueSchema.dependencies);
        const n = properties.length;
        for (let i = 0; i < n; i += 1) {
          const key = properties[i];
          if (value[key] !== undefined) {
            let error;
            if (isArray(valueSchema.dependencies[key])) {
              error = checkSchema(
                {
                  required: valueSchema.dependencies[key],
                },
                value,
              );
            } else {
              error = checkSchema(valueSchema.dependencies[key], value);
            }
            if (error) {
              return error;
            }
          }
        }
      }
      if (
        typeof valueSchema.minProperties === 'number' &&
        size(value) < valueSchema.minProperties
      ) {
        return {
          message: `Expected at least ${valueSchema.minProperties} properties`,
        };
      }
      if (
        typeof valueSchema.maxProperties === 'number' &&
        size(value) > valueSchema.maxProperties
      ) {
        return {
          message: `Expected at most ${valueSchema.maxProperties} properties`,
        };
      }
    }
    if (isArray(value)) {
      if (isArray(valueSchema.items)) {
        const errors = {};
        forEach(value, (valueAtKey, key) => {
          if (key < valueSchema.items.length) {
            const error = checkSchema(valueSchema.items[key], valueAtKey);
            if (error) {
              errors[key] = error;
            }
          } else if (has(valueSchema, 'additionalItems')) {
            const error = checkSchema(valueSchema.additionalItems, valueAtKey);
            if (error) {
              errors[key] = error;
            }
          }
        });
        if (!isEmpty(errors)) {
          return {
            errors,
          };
        }
      } else if (has(valueSchema, 'items')) {
        const errors = {};
        forEach(value, (valueAtKey, key) => {
          const error = checkSchema(valueSchema.items, valueAtKey);
          if (error) {
            errors[key] = error;
          }
        });
        if (!isEmpty(errors)) {
          return {
            errors,
          };
        }
      }
      if (has(valueSchema, 'contains')) {
        const n = value.length;
        let isMatch = false;
        for (let i = 0; i < n; i += 1) {
          const error = checkSchema(valueSchema.contains, value[i]);
          if (!error) {
            isMatch = true;
            break;
          }
        }
        if (!isMatch) {
          return {
            message: 'Value does not match any of the specified types',
          };
        }
      }
      if (
        typeof valueSchema.minItems === 'number' &&
        value.length < valueSchema.minItems
      ) {
        return {
          message: `Expected at least ${valueSchema.minItems} item(s)`,
        };
      }
      if (
        typeof valueSchema.maxItems === 'number' &&
        value.length > valueSchema.maxItems
      ) {
        return {
          message: `Expected at most ${valueSchema.maxItems} item(s)`,
        };
      }
    }
    if (has(valueSchema, 'minimum') && typeof value === 'number') {
      if (value < valueSchema.minimum) {
        return {
          message: `Expected value to be at least ${valueSchema.minimum}`,
        };
      }
    }
    if (isArray(valueSchema.anyOf)) {
      const n = valueSchema.anyOf.length;
      let isMatch = false;
      for (let i = 0; i < n; i += 1) {
        const schemaToCheck = valueSchema.anyOf[i];
        const error = checkSchema(schemaToCheck, value);
        if (!error) {
          isMatch = true;
          break;
        }
      }
      if (!isMatch) {
        return {
          message: 'Value does not match any of the specified types',
        };
      }
    }
    if (isArray(valueSchema.allOf)) {
      const n = valueSchema.allOf.length;
      for (let i = 0; i < n; i += 1) {
        const schemaToCheck = valueSchema.allOf[i];
        const error = checkSchema(schemaToCheck, value);
        if (error) {
          return error;
        }
      }
    }
    if (isArray(valueSchema.oneOf)) {
      const n = valueSchema.oneOf.length;
      let isMatch = false;
      for (let i = 0; i < n; i += 1) {
        const schemaToCheck = valueSchema.oneOf[i];
        const error = checkSchema(schemaToCheck, value);
        if (!error) {
          if (isMatch) {
            return {
              message: 'Value matches more than one type',
            };
          }
          isMatch = true;
        }
      }
      if (!isMatch) {
        return {
          message: 'Value does not match any of the specified types',
        };
      }
    }
    if (has(valueSchema, 'if')) {
      const error = checkSchema(valueSchema.if, value);
      if (!error && has(valueSchema, 'then')) {
        return checkSchema(valueSchema.then, value);
      }
      if (error && has(valueSchema, 'else')) {
        return checkSchema(valueSchema.else, value);
      }
    }
    if (has(valueSchema, 'not')) {
      const error = checkSchema(valueSchema.not, value);
      if (!error) {
        return {
          message: 'Expected value not to match schema',
        };
      }
    }
    return undefined;
  };

  getValidateCache[rootURI] = {
    checkSchema,
  };

  return checkSchema;
}

const defaultCheckSchema = createCheckSchema({});
export default defaultCheckSchema;

export function getAllErrors(error) {
  const allErrors = [];
  if (!error) {
    return allErrors;
  }
  if (error.message) {
    allErrors.push({
      message: error.message,
    });
  }
  forEach(error.errors, (nestedError, key) => {
    const allNestedErrors = getAllErrors(nestedError);
    forEach(allNestedErrors, (nested) => {
      allErrors.push({
        key: nested.key ? `${key}.${nested.key}` : key,
        message: nested.message,
      });
    });
  });
  return allErrors;
}

export function assignError(errors, name, message) {
  const parts = name.split('.');
  if (parts.length === 0) {
    // eslint-disable-next-line no-param-reassign
    errors.message = message;
  } else {
    const key = parts[0];
    if (!errors.errors) {
      // eslint-disable-next-line no-param-reassign
      errors.errors = {};
    }
    if (!errors.errors[key]) {
      // eslint-disable-next-line no-param-reassign
      errors.errors[key] = {};
    }
    if (parts.length === 1) {
      // eslint-disable-next-line no-param-reassign
      errors.errors[key].message = message;
    }
    if (parts.length > 1) {
      assignError(errors.errors[key], parts.slice(1).join('.'), message);
    }
  }
}

export function mergeErrors(errors, moreErrors) {
  if (!errors) {
    return moreErrors;
  }
  if (!moreErrors) {
    return errors;
  }
  const newErrors = {
    ...errors,
    ...moreErrors,
  };
  if (errors.errors && moreErrors.errors) {
    newErrors.errors = {};
    forEach(errors.errors, (errorsAtKey, key) => {
      newErrors.errors[key] = mergeErrors(errorsAtKey, moreErrors.errors[key]);
    });
    forEach(moreErrors.errors, (errorsAtKey, key) => {
      if (!errors.errors[key]) {
        newErrors.errors[key] = errorsAtKey;
      }
    });
  }
  return newErrors;
}

export function getLeafErrors(errors) {
  if (!errors) {
    return undefined;
  }
  if (!errors.errors) {
    return errors.message;
  }
  return mapValues(errors.errors, getLeafErrors);
}

export function getOneError(error) {
  if (!error) {
    return undefined;
  }
  if (error.message) {
    return {
      message: error.message,
    };
  }
  if (isPlainObject(error.errors)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in error.errors) {
      if (has(error.errors, key)) {
        const nested = getOneError(error.errors[key]);
        if (nested && nested.message) {
          return {
            key: nested.key ? `${key}.${nested.key}` : key,
            message: nested.message,
          };
        }
      }
    }
  }
  return undefined;
}

export function getErrorMessage(error) {
  if (!error) {
    return undefined;
  }
  const { key, message } = getOneError(error);
  if (!message) {
    return undefined;
  }
  if (key) {
    return `${message} at "${key}"`;
  }
  return message;
}

export function createValidator(valueSchema) {
  const checkSchema = createCheckSchema({});
  const validate = (value) => {
    const error = checkSchema(valueSchema, value);
    if (error) {
      throw new Error(getErrorMessage(error));
    }
  };
  return validate;
}

export const isOfType = (valueSchema, value) =>
  !defaultCheckSchema(valueSchema, value);
